// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
import { toast } from "react-toastify";

const { storageTokenKeyName, storageRefreshTokenKeyName } = useJwt.jwtConfig;

const initialUser = () => {
	const item = window.localStorage.getItem("userData");
	//** Parse stored json or if none return initialValue
	return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
	name: "authentication",
	initialState: {
		userData: initialUser(),
	},
	reducers: {
		handleLogin: (state, { payload }) => {
			// console.log({payload})
			const userData = payload.userData;
			if (!userData.role) {
				toast.error("Доступ запрещен!");
				handleLogout();
				return;
			}

			state.userData = userData;
			state[storageTokenKeyName] = payload[storageTokenKeyName];
			state[storageRefreshTokenKeyName] = payload[storageRefreshTokenKeyName];

			localStorage.setItem("userData", JSON.stringify(userData));
			localStorage.setItem(storageTokenKeyName, payload[storageTokenKeyName]);
			localStorage.setItem(storageRefreshTokenKeyName, payload[storageRefreshTokenKeyName]);
		},
		handleLogout: (state) => {
			console.log("asd");
			state.userData = {};
			state[storageTokenKeyName] = null;
			state[storageRefreshTokenKeyName] = null;
			// ** Remove user, accessToken & refreshToken from localStorage
			localStorage.removeItem("userData");
			localStorage.removeItem(storageTokenKeyName);
			localStorage.removeItem(storageRefreshTokenKeyName);
		},
		test: () => {
			console.log();
		},
	},
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;

