// Custom

export const isEmpty = (any) => {
	if (typeof any === 'boolean') return false
	if (typeof any === 'string') return !any?.trim()
	if (typeof any === 'number') return false
	if (Array.isArray(any)) return !any.length
	if (typeof any === 'object') return isObjEmpty(any)
	return !any
}

export const isNotEmpty = (any) => !isEmpty(any)

export const debounce = function (func, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, timeout)
	}
}

export const includesSomeSubstring = (string, arr = []) =>
	arr.some(subStr => string.includes(subStr))

export const getLocaleDate = (date) => new Date(date).toLocaleDateString()

export const getLocaleFullDate = (date) => new Date(date).toLocaleString()


// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

export const getDateNow = () => new Date(Date.now())

export const isDefined = val => val !== undefined

export const getFullNameFromObj = (userData) => {
	if (!userData) return '-'
	const {first_name, middle_name, last_name} = userData
	
	if ([first_name, middle_name, last_name].every(part => !isDefined(part)))
		return '-'
	
	return `${last_name} ${first_name}\u{00A0}${middle_name}`;
}

// export const objectMap = (obj, mapper) => {
// 	// console.log(obj, mapper)
// 	return Object.fromEntries(Object.entries(obj).map(mapper));
// }

//получает значение глубоко вложенного поля объекта
export function getNestedValue(obj, path) {
	const keys = path.split('.')
	return keys.reduce((val, key) => val?.[key], obj)
}

export const likeArrayObject = {
	every: (obj, cb) => Object.entries(obj).every(cb),
	some: (obj, cb) => Object.entries(obj).some(cb),
	map: (obj, cb) => Object.fromEntries(Object.entries(obj).map(cb)),
	forEach: (obj, cb) => Object.entries(obj).forEach(cb),
	filter: (obj, cb) => Object.fromEntries(Object.entries(obj).filter(cb)),
}

// export const objectForEach = (obj, forEachFunc) => {
// 	// console.log(obj, forEachFunc)
// 	Object.entries(obj).forEach(forEachFunc)
// }

export const setCookie = (name, value, options = {}) => {
	options = {path: '/', ...options}
	
	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}
	
	let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
	
	for (let optionKey in options) {
		updatedCookie += "; " + optionKey;
		let optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}
	
	document.cookie = updatedCookie;
};

export const getCookie = name => {
	let matches = document.cookie.match(new RegExp(
		"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
};

export function deleteCookie(name) {
	setCookie(name, "", {
		'max-age': -1
	})
}


// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
	const today = new Date()
	return (
		/* eslint-disable operator-linebreak */
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
		/* eslint-enable */
	)
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {month: 'short', day: 'numeric', year: 'numeric'}) => {
	if (!value) return value
	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
	const date = new Date(value)
	let formatting = {month: 'short', day: 'numeric'}
	
	if (toTimeForCurrentDay && isToday(date)) {
		formatting = {hour: 'numeric', minute: 'numeric'}
	}
	
	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
	...theme,
	colors: {
		...theme.colors,
		primary25: '#7367f01a', // for option hover bg-color
		primary: '#7367f0', // for selected option bg-color
		neutral10: '#7367f0', // for tags bg-color
		neutral20: '#ededed', // for input border-color
		neutral30: '#ededed' // for input hover border-color
	}
})

