import {useEffect} from 'react';
// import {KeycloakContext} from '../../../context/KeycloakProvider';
import axios from 'axios';
// import {useKeycloak} from '@react-keycloak/web';
import {toast} from 'react-toastify';
import {useNetworkState} from '@react-hookz/web';
import {showRequestErrorMessage} from './showRequestErrorMessage';
import {useRetryRequest} from './hooks/useRetryRequest';
import {useAbortRequest} from './hooks/useAbortRequest';
import {oldPaths} from '../router/routesConstants';
import {useHistory} from 'react-router-dom';

export const getExtraFullNameOrdering = (ordering) => {
	const fullNames = [
		ordering,
		ordering.replace('last_name', 'first_name'),
		ordering.replace('last_name', 'middle_name')
	]
	return fullNames.join(',')
}

export const AxiosInterceptorsHelper = () => {
	const history = useHistory()
	
	const {online: isOnline} = useNetworkState()
	
	const isRetryRequest = useRetryRequest()
	const checkSameRequest = useAbortRequest()
	
	
	useEffect(() => {
		axios.interceptors.request.use(async (config) => {
				const {method, params} = config
				// console.log({config})
				if (params?.ordering && params.ordering.includes('last_name'))
					params.ordering = getExtraFullNameOrdering(params.ordering)
			
				// console.log('withOtpRequest(config)', withOtpRequest(config), {config})
			
				if (method === 'get') {
					config = checkSameRequest(config)
					config.timeout = 5000
				}
				
				return config
			},
			(error) => Promise.reject(error)
		)
	}, [])
	
	useEffect(() => {
		axios.interceptors.response.use((response) => {
			const {status} = response || {}
			
			if (status > 500)
				toast(`Ошибка сервера: ${status}`, {variant: 'error', position: 'top-center'})
			
			return response
		}, (error) => {
			const {response, config, code, message} = error || {}
			const skipError = config?.skipErrorHandling
			
			if (isRetryRequest(code))
				return axios.request(config)
			
			!skipError && response?.status !== 400 &&
			showRequestErrorMessage(response, message, isOnline)
			
			const {status, data} = response || {}
			
			if (status === 401) {
				toast.error(data?.detail)
			}
			
			if (status === 403) {
				// console.log({status})
				history.push(oldPaths.auth.LOGIN)
			}
			
			return Promise.reject(error)
		})
	}, [isOnline])
	
	return null
}
