// ** Imports createContext function
import {createContext, useEffect} from 'react'

// ** Imports createContextualCan function
import {createContextualCan, useAbility} from '@casl/react'
import {Ability} from '@casl/ability';
import {ROLES} from '../../constants/roles';
import {useSelector} from 'react-redux';
import {oldWords} from '../../constants/OLD/oldWords';
import PropTypes from 'prop-types';
import {ACCESS_CONTROL} from '../../constants/accessControl';
import {COMMON_WORDS} from '../../constants/common/commonWords';

const {action: {MANAGE}} = ACCESS_CONTROL
// ** Create Context
export const AbilityContext = createContext({})

// ** Init Can Context
export const Can = ({I, a, passThrough, ...props}) => {
	const _Can = createContextualCan(AbilityContext.Consumer)
	
	return <_Can {...{I, a, passThrough}}>{props.children}</_Can>
}

Can.propTypes = {
	I: PropTypes.oneOf([
		'create',
		'read',
		'update',
		'delete',
		'manage']),
	a: PropTypes.string
}

const {MANAGER, USER} = ROLES

const abilities = {
	[USER]: [
		{action: MANAGE, subject: COMMON_WORDS.PROFILE},
		{action: MANAGE, subject: COMMON_WORDS.ORDERS},
		{action: MANAGE, subject: COMMON_WORDS.ORDERS_MAIL},
		{action: MANAGE, subject: COMMON_WORDS.ORDERS_TRANSPORT},
		{action: MANAGE, subject: COMMON_WORDS.CALCULATOR},
	],
	[MANAGER]: [
		{action: MANAGE, subject: COMMON_WORDS.MAIN},
		{action: MANAGE, subject: COMMON_WORDS.ORDERS},
		{action: MANAGE, subject: COMMON_WORDS.ORDERS_MAIL},
		{action: MANAGE, subject: COMMON_WORDS.ORDERS_TRANSPORT},
		{action: MANAGE, subject: COMMON_WORDS.NEWS_AND_SPECIAL_OFFERS},
	
	],
}

export const useUpdateAbility = () => {
	const ability = useContextAbility()
	const {userData} = useSelector(state => state.auth)
	
	useEffect(() => {
		if (!userData.role) return
		const {rules} = new Ability(abilities[userData.role])
		ability.update(rules)
	}, [userData.role])
	
	return ability
}

export const useContextAbility = () => useAbility(AbilityContext)

