// ** Initial user ability
import {ACCESS_CONTROL} from '../../constants/accessControl';
import {oldWords} from '../../constants/OLD/oldWords';

export const initialAbility = [
	{
		action: ACCESS_CONTROL.action.READ,
		subject: oldWords.LOGIN
	}
]

export const _ = undefined
