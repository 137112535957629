export const oldWords = {
	EDIT: 'edit',
	AUTH: 'auth',
	LOGIN: 'login',
	ROLES: 'roles',
	USERS: 'users',
	ORDERS: 'orders',
	MOVERS: 'movers',
	BRIGADE: 'brigade',
	PROFILE: 'profile',
	ANALYSTS: 'analysts',
	CUSTOMERS: 'customers',
	ONE_CUSTOMER: 'customer',
	STATISTICS: 'statistics',
	BRIGADIERS: 'brigadiers',
	ONE_BRIGADIER: 'brigadier',
	CONTRACTORS: 'contractors',
	SUPPORT: 'tech_support',
	REGISTRATION: 'registration',
	PASS_RECOVERY: 'pass_recovery',
}

const {
	ROLES, USERS, ORDERS, MOVERS,
	BRIGADE, PROFILE, ANALYSTS, CUSTOMERS, STATISTICS,
	BRIGADIERS, CONTRACTORS, SUPPORT,
} = oldWords


export const oldRusWords = {
	[STATISTICS]: 'Статистика',
	[CONTRACTORS]: 'Подрядчики',
	[BRIGADIERS]: 'Бригадиры',
	[MOVERS]: 'Грузчики',
	[ORDERS]: 'Заказы',
	[CUSTOMERS]: 'Клиенты',
	[BRIGADE]: 'Бригада',
	[ROLES]: 'Роли',
	[PROFILE]: 'Профиль',
	[USERS]: 'Пользователи',
	[ANALYSTS]: 'Аналитики',
	[SUPPORT]: 'Техподдержка',
	ADD: 'Добавление',
	INFO: 'Инфо',
	EDIT: 'Редактирование',
}