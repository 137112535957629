import {oldWords} from '../constants/OLD/oldWords';
import {COMMON_WORDS} from '../constants/common/commonWords';

const prependSlash = word => `/${word}`
const insertSlashes = (...args) => args.join('/')
const appendNew = word => `${word}/new`
const appendIdParam = (word, id = ':id', ...args) => insertSlashes(word, id, ...args)

const {
	AUTH,
	EDIT,
	LOGIN,
	REGISTRATION,
	PASS_RECOVERY,
	ORDERS,
	CALCULATOR,
	PROFILE,
	USERS,
	ROLES,
	NEWS_AND_SPECIAL_OFFERS,
	MAIN,
	NEW,
	ORDERS_MAIL,
	ORDERS_TRANSPORT,
	INSTRUCTION,
} = COMMON_WORDS

export const ROUTES_PATHS = {
	AUTH: {
		LOGIN: prependSlash(insertSlashes(AUTH, LOGIN)),
		REGISTRATION: prependSlash(insertSlashes(AUTH, REGISTRATION)),
		PASS_RECOVERY: prependSlash(insertSlashes(AUTH, PASS_RECOVERY)),
	},
	MAIN: prependSlash(MAIN),
	ORDERS: {
		MAIL: {
			LIST: prependSlash(ORDERS_MAIL),
			CREATE: prependSlash(insertSlashes(ORDERS_MAIL, NEW)),
			EDIT: (id) => prependSlash(appendIdParam(ORDERS_MAIL, id, EDIT)),
			INFO:  (id) => prependSlash(appendIdParam(ORDERS_MAIL, id))
		},
		TRANSPORT: {
			LIST: prependSlash(ORDERS_TRANSPORT),
			CREATE: prependSlash(insertSlashes(ORDERS_TRANSPORT, NEW)),
			EDIT: (id) => prependSlash(appendIdParam(ORDERS_TRANSPORT, id, EDIT)),
			INFO: (id) => prependSlash(appendIdParam(ORDERS_TRANSPORT, id))
		},
	},
	
	
	NEWS_AND_SPECIAL_OFFERS: {
		LIST: prependSlash(NEWS_AND_SPECIAL_OFFERS),
		CREATE: prependSlash(insertSlashes(NEWS_AND_SPECIAL_OFFERS, NEW)),
		EDIT: (id) => prependSlash(appendIdParam(NEWS_AND_SPECIAL_OFFERS, id, EDIT)),
		INFO: (id) => prependSlash(appendIdParam(NEWS_AND_SPECIAL_OFFERS, id))
	},
	CALCULATOR: prependSlash(CALCULATOR),
	PROFILE: {
		INFO: prependSlash(PROFILE),
		EDIT: prependSlash(insertSlashes(PROFILE, EDIT)),
		INSTRUCTION: prependSlash(insertSlashes(PROFILE, INSTRUCTION)),
	},
}


export const oldPaths = {
	persons: {
		new: {
			CONTRACTORS: prependSlash(appendNew(oldWords.CONTRACTORS)),
			BRIGADIERS: prependSlash(appendNew(oldWords.BRIGADIERS)),
			MOVERS: prependSlash(appendNew(oldWords.MOVERS)),
			CUSTOMERS: prependSlash(appendNew(oldWords.CUSTOMERS)),
			USERS: prependSlash(appendNew(USERS)),
		},
		info: {
			CONTRACTORS: (id) => prependSlash(appendIdParam(oldWords.CONTRACTORS, id)),
			BRIGADIERS: (id) => prependSlash(appendIdParam(oldWords.BRIGADIERS, id)),
			MOVERS: (id) => prependSlash(appendIdParam(oldWords.MOVERS, id)),
			CUSTOMERS: (id) => prependSlash(appendIdParam(oldWords.CUSTOMERS, id)),
			USERS: (id) => prependSlash(appendIdParam(USERS, id)),
			ORDER: (id) => prependSlash(appendIdParam(ORDERS, id)),
		},
		edit: {
			CONTRACTORS: (id) => prependSlash(appendIdParam(oldWords.CONTRACTORS, id, oldWords.EDIT)),
			BRIGADIERS: (id) => prependSlash(appendIdParam(oldWords.BRIGADIERS, id, oldWords.EDIT)),
			MOVERS: (id) => prependSlash(appendIdParam(oldWords.MOVERS, id, oldWords.EDIT)),
			CUSTOMERS: (id) => prependSlash(appendIdParam(oldWords.CUSTOMERS, id, oldWords.EDIT)),
			USERS: (id) => prependSlash(appendIdParam(USERS, id, oldWords.EDIT)),
		},
		CONTRACTORS: prependSlash(oldWords.CONTRACTORS),
		BRIGADIERS: prependSlash(oldWords.BRIGADIERS),
		MOVERS: prependSlash(oldWords.MOVERS),
		CUSTOMERS: prependSlash(oldWords.CUSTOMERS),
		USERS: prependSlash(USERS)
	},
	ORDERS: prependSlash(ORDERS),
	info: {
		ORDERS: (id) => prependSlash(appendIdParam(ORDERS, id)),
	},
	statistics: {
		USERS: prependSlash(insertSlashes(oldWords.STATISTICS, USERS)),
		CONTRACTORS: prependSlash(insertSlashes(oldWords.STATISTICS, oldWords.CONTRACTORS)),
		BRIGADE: prependSlash(insertSlashes(oldWords.STATISTICS, oldWords.BRIGADE)),
		ORDERS: prependSlash(insertSlashes(oldWords.STATISTICS, ORDERS))
	},
	BRIGADE: prependSlash(oldWords.BRIGADE),
	roles: {
		ANALYSTS: prependSlash(insertSlashes(oldWords.ANALYSTS)),
		SUPPORT: prependSlash(insertSlashes(oldWords.SUPPORT)),
		new: {
			SUPPORT: prependSlash(appendNew(insertSlashes(oldWords.SUPPORT))),
		},
		info: {
			SUPPORT: (id) => prependSlash(appendIdParam(insertSlashes(oldWords.SUPPORT), id)),
		},
		edit: {
			SUPPORT: (id) => prependSlash(appendIdParam(insertSlashes(oldWords.SUPPORT), id, oldWords.EDIT)),
		},
	},
	PROFILE: prependSlash(PROFILE),
	auth: {
		LOGIN: prependSlash(insertSlashes(AUTH, LOGIN)),
		REGISTRATION: prependSlash(insertSlashes(AUTH, REGISTRATION)),
		PASS_RECOVERY: prependSlash(insertSlashes(AUTH, PASS_RECOVERY)),
	}
	
	// BRIGADIERS: prependSlash(BRIGADIERS),
	// CONTRACTORS: prependSlash(CONTRACTORS),
	// CUSTOMERS: prependSlash(CUSTOMERS),
	// MOVERS: prependSlash(MOVERS)
}
