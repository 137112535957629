import { ACCESS_CONTROL } from "../accessControl";

export const COMMON_WORDS = {
	EDIT: "edit",
	CREATE: "create",
	ADD: "add",
	NEW: "new",
	INFO: "info",
	MAIN: "main",
	AUTH: "auth",
	LOGIN: "login",
	ROLES: "roles",
	USERS: "users",
	ORDERS: "orders",
	ORDERS_TRANSPORT: "orders_transport",
	ORDERS_MAIL: "orders_mail",
	// MOVERS: 'movers',
	// BRIGADE: 'brigade',
	PROFILE: "profile",
	// ANALYSTS: 'analysts',
	// CUSTOMERS: 'customers',
	// ONE_CUSTOMER: 'customer',
	// STATISTICS: 'statistics',
	// BRIGADIERS: 'brigadiers',
	// ONE_BRIGADIER: 'brigadier',
	// CONTRACTORS: 'contractors',
	// SUPPORT: 'tech_support',
	CALCULATOR: "calculator",
	NEWS_AND_SPECIAL_OFFERS: "news_and_special_offers",
	REGISTRATION: "registration",
	PASS_RECOVERY: "pass_recovery",
	EXIT: "exit",
	INSTRUCTION: "instruction"
};

const {
	PROFILE,
	EDIT,
	ORDERS,
	CALCULATOR,
	MAIN,
	NEWS_AND_SPECIAL_OFFERS,
	ROLES,
	USERS,
	PASS_RECOVERY,
	REGISTRATION,
	NEW,
	AUTH,
	LOGIN,
	CREATE,
	INFO,
	ADD,
	ORDERS_MAIL,
	ORDERS_TRANSPORT,
	EXIT,
	// INSTRUCTION,
} = COMMON_WORDS;

const { UPDATE } = ACCESS_CONTROL.action;

export const RUS_COMMON_WORDS = {
	[EDIT]: "Редактирование",
	[ORDERS]: "Заказы",
	[CALCULATOR]: "Калькулятор",
	[MAIN]: "Главная",
	[ORDERS_TRANSPORT]: "Заказы на перевозку",
	[ORDERS_MAIL]: "Почтовые заказы",
	[NEWS_AND_SPECIAL_OFFERS]: "Новости и акции",
	[USERS]: "Пользователи",
	[CREATE]: "Создание",
	// [ORDERS]: 'Заявки',
	[ROLES]: "Роли",
	[PROFILE]: "Мои данные",
	[USERS]: "Пользователи",
	[ADD]: "Добавление",
	[INFO]: "Инфо",
	[UPDATE]: "Редактирование",
	[EXIT]: "Выйти",
	// [INSTRUCTION]: "Инструкции",
};

