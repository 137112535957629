import jwt from './jwt/useJwt';
import {oldPaths, ROUTES_PATHS} from '../router/routesConstants';
import {ROLES} from '../constants/roles';
const {MANAGER, USER} = ROLES

export const isUserLoggedIn = () => {
	return Boolean(
		localStorage.getItem('userData') &&
		localStorage.getItem(jwt.jwtConfig.storageTokenKeyName))
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const parseToken = token => JSON.parse(window.atob(token.split('.')[1]))

export const getHomeRouteForLoggedInUser = (userRole = getUserData()?.role) => {
	const _paths = {
		[MANAGER]: ROUTES_PATHS.MAIN,
		[USER]: ROUTES_PATHS.PROFILE.INFO,
	}
	
	return _paths[userRole] || oldPaths.auth.LOGIN
}


