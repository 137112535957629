// ** React Imports
import { lazy, Suspense } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";

// ** Intl & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "react-toastify/dist/ReactToastify.css";
// import '@styles/react/libs/toastify/toastify.scss'
// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/index.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { AxiosInterceptorsHelper } from "./api/AxiosInterceptorsHelper";
import "./api/axios/axiosConfig";

import { AbilityContext } from "./utility/context/Can";
import ability from "./configs/acl/ability";

import "./configs/yup/yupConfig.js";
import { BrowserRouter } from "react-router-dom";
import { MyModalContainer } from "./components/MyModal/MyModalContainer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "@styles/react/libs/flatpickr/flatpickr.scss";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false, // default: true
			// onSuccess: data => { return console.log({'onSuccess': data})},
			select: (data) => data.data,
			// refetchInterval:
		},
	},
});

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<Provider store={store}>
			<Suspense fallback={<Spinner />}>
				<AbilityContext.Provider value={ability}>
					<ThemeContext>
						<BrowserRouter basename={process.env.REACT_APP_BASENAME}>
							<AxiosInterceptorsHelper />
							<LazyApp />
							<ToastContainer newestOnTop theme={"colored"} position={"top-center"} />
							<MyModalContainer />
						</BrowserRouter>
					</ThemeContext>
				</AbilityContext.Provider>
			</Suspense>
		</Provider>
	</QueryClientProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
