import {useRef} from 'react';

export const useAbortRequest = () => {
   const prevDataRef = useRef({})
   
   const checkSameRequest = (config) => {
      const controller = new AbortController()
      config.signal = controller.signal
      
      // для отмены предыдущего запроса с переменными в секциях урла
      // const abortEndpointsParts = ['globalsearch', 'logs', 'devices/setup/section']
      
      const {url: prevUrl, method: prevMethod} = prevDataRef.current
      const {url, method} = config
      
      const sameRequest = prevUrl === url && prevMethod === method
      
      // const sameCustomRequest = () => {
      // 	if (!prevUrl) return false
      // 	return includesSomeSubstring(url, abortEndpointsParts)
      // 		&& includesSomeSubstring(prevUrl, abortEndpointsParts)
      // }
      
      // if (sameRequest || sameCustomRequest())
      if (sameRequest) prevDataRef.current?.abort()
      
      prevDataRef.current = {url, method, abort: () => controller.abort()}
      
      return config
   }
   
   return checkSameRequest
}