//acl - Access Control

export const ACCESS_CONTROL = {
	action: {
		CREATE: 'create',
		READ: 'read',
		UPDATE: 'update',
		DELETE: 'delete',
		MANAGE: 'manage',
	},
	target: {
		ALL: 'all'
	}
}