export const ROLES = {
	MANAGER: 'manager',
	USER: 'user',
}

export const RUS_ROLES = {
	MANAGER: 'Менеджер',
	USER: 'Пользователь',
}

