import {useRef} from 'react';

export function useRetryRequest() {
	const retryCounter = useRef(5)
	
	return (code) => {
		if (code === 'ECONNABORTED' && retryCounter.current > 0) {
			retryCounter.current--
			return true
		}
		
		retryCounter.current = 5
	}
}