import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import {parseToken} from '../../../auth/utils';

export default class JwtService {
	// ** jwtConfig <= Will be used by this service
	jwtConfig = {...jwtDefaultConfig}
	
	// ** For Refreshing Token
	isAlreadyFetchingAccessToken = false
	
	// ** For Refreshing Token
	subscribers = []
	
	constructor(jwtOverrideConfig) {
		this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}
		
		// ** Request Interceptor
		axios.interceptors.request.use(
			config => {
				// ** Get token from localStorage
				const accessToken = this.getToken()
				
				// ** If token is present add it to request's Authorization Header
				if (accessToken) {
					// ** eslint-disable-next-line no-param-reassign
					config.headers.Authorization =
						`${this.jwtConfig.tokenType} ${accessToken}`
				}
				return config
			},
			error => Promise.reject(error)
		)
		
		// ** Add request/response interceptor
		axios.interceptors.response.use(
			response => response,
			error => {
				const {config, response} = error
				const originalRequest = config
				
				if (response?.status === 401 && this.getToken()) {
					this.isAlreadyFetchingAccessToken && this.logout()
					
					if (!this.isAlreadyFetchingAccessToken) {
						this.isAlreadyFetchingAccessToken = true
						
						this.refreshToken()
							.then(({data}) => {
								this.isAlreadyFetchingAccessToken = false
								// ** Update accessToken in localStorage
								this.setToken(data.access)
								this.setRefreshToken(data.refresh)
								this.onAccessTokenFetched(data.access)
							})
						
						// 	.catch(err => {
						// 	console.log(err)
						// 	this.logout()
						// })
					}
					
					const retryOriginalRequest = new Promise(resolve => {
						this.addSubscriber(accessToken => {
							// ** Make sure to assign accessToken according to your response.
							// ** Check: https://pixinvent.ticksy.com/ticket/2413870
							// ** Change Authorization header
							originalRequest.headers.Authorization =
								`${this.jwtConfig.tokenType} ${accessToken}`
							resolve(axios(originalRequest))
						})
					})
					return retryOriginalRequest
				}
				
				return Promise.reject(error)
			}
		)
	}
	
	onAccessTokenFetched(accessToken) {
		this.subscribers = this.subscribers.filter(callback => callback(accessToken))
	}
	
	addSubscriber(callback) {
		this.subscribers.push(callback)
	}
	
	getToken() {
		return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
	}
	
	getRefreshToken() {
		return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
	}
	
	setToken(value) {
		value && localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
	}
	
	setRefreshToken(value) {
		value &&
		localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
	}
	
	login(...args) {
		// console.log('login')
		return axios.post(this.jwtConfig.loginEndpoint, ...args)
	}
	
	logout(action) {
		// console.log('logout')
		localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
		localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
		action?.()
	}
	
	register() {
		return axios.post(this.jwtConfig.registerEndpoint, arguments[0])
	}
	
	refreshToken() {
		return axios.post(this.jwtConfig.refreshEndpoint, {
			refresh: this.getRefreshToken()
		})
	}
	
	getRole() {
		return parseToken(this.getToken())
	}
	
	// getEmail() {
	// 	return parseToken(this.getToken()).email
	// }
}
